<template>
   <div class="storage" v-loading.fullscreen.lock="loading" 
     element-loading-text="请求数据中" 
     element-loading-background="rgba(255, 255, 255, 0.5)">
      <div class="append">
        <div class="seach">
           <el-select 
            clearable
            @clear="tableSearch()"
            style="width:224px" 
            placeholder="请选择店铺" 
            v-model="pageInfoList.storeName" 
           >
             <el-option-group v-for="group in multipleSelectOption"
               :key="group.id"
               :label="group.market"
               >
               <el-option v-for="item in group.marketplaces"
                :key="item.id"
                :label="item.value"
                :value="group.id+'-'+item.id"
               >
               </el-option>
             </el-option-group>
           </el-select>  
           <el-select style="width:224px;" placeholder="账号状态" v-model="pageInfoList.accountStatus" clearable @change="tableSearch">
             <el-option 
              v-for="item in statusList" 
              :key="item.id" 
              :label="item.value" 
              :value="item.id"
             >{{item.value}}
            </el-option>
           </el-select>  
           <el-button type="primary" icon="el-icon-search" @click="tableSearch">查询</el-button>
           <el-button type="primary" icon="el-icon-circle-plus-outline" @click="addSubAccount">增加子账号</el-button>
           <!-- 列表区域 -->
           <el-table
             border
             :data="tableDataList"
             style="width: 100%"
             :header-cell-style="{background:'#EFEFEF',textAlign:'center'}"
             >
              <el-table-column label="昵称" prop="userName" align="center"></el-table-column>
              <el-table-column label="账号状态" prop="status" align="center">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.status"
                      active-text="启用"
                      inactive-text="禁用"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      active-value="1"
                      inactive-value="2"
                      @change="changeAccountStatus($event, scope.row)"
                      >
                    </el-switch>
                  </template>
              </el-table-column>
              <el-table-column label="角色" prop="roleName" align="center"></el-table-column>
              <el-table-column label="登陆账号" prop="userAccount" align="center"></el-table-column>
              <el-table-column label="手机号" prop="userPhone" align="center"></el-table-column>
              <el-table-column label="已授权店铺数" align="center" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-tooltip  effect="dark" placement="top">
                    <div slot="content">{{scope.row.shopNames}}</div>
                    <span>{{ scope.row.shopCounts }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="最后登陆创建时间" prop="lastLoginTime" align="center"></el-table-column>
              <el-table-column label="备注" prop="tips" align="center"></el-table-column>
              <el-table-column label="操作" align="center">
                 <template slot-scope="scope">
                    <el-link :underline="false" type="primary" icon="el-icon-edit" @click="handleEdit(scope.row)">编辑</el-link>
                 </template>
              </el-table-column>
           </el-table>
           <!-- 分页区域 -->
           <el-pagination 
             @size-change="handleSizeChange" 
             @current-change="handleCurrentChange"
             layout="total, sizes, prev, pager, next, jumper" 
             :page-sizes="[20, 30, 50, 100]" 
             :total="total"
             :page-size="pageInfoList.pageSize" 
             :current-page="pageInfoList.current" 
           >
           </el-pagination>
        </div>
        <!-- 添加子账号 -->
         <el-dialog 
           width="40%" 
           title="添加子账号" 
           :visible.sync="dialogFormVisible"
         >
          <div class="nav">
              <el-divider content-position="center">子账号信息</el-divider>
          </div>
          <el-form :model="dataForm" :rules="dataFormRules" ref="dataFormRef" :inline="true">
            <el-form-item label="昵称：" :label-width="formLabelWidth" prop="nickName">
              <el-input v-model="dataForm.nickName" autocomplete="off" placeholder="请输入昵称"></el-input>
            </el-form-item>
            <el-form-item label="角色：" :label-width="formLabelWidth" prop="Role">
              <el-select v-model="dataForm.Role" placeholder="请选择角色" style="width: 175px">
                <el-option v-for="item in roleList" :key="item.id" :label="item.value" :value="item.id">{{item.value}}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="登陆账号：" :label-width="formLabelWidth" prop="Account">
                <el-input v-model="dataForm.Account" autocomplete="off" placeholder="请输入登陆账号"></el-input>
            </el-form-item>
            <el-form-item label="登陆手机号：" :label-width="formLabelWidth" prop="mobilePhone">
                <el-input type="number" v-model="dataForm.mobilePhone" placeholder="请输入登陆手机号"></el-input>
            </el-form-item>
            <el-form-item label="登陆密码：" :label-width="formLabelWidth" prop="pass">
                <el-input type="password" v-model="dataForm.pass" placeholder="请输入登陆密码"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" :label-width="formLabelWidth" prop="checkPass">
                <el-input type="password" v-model="dataForm.checkPass" autocomplete="off" placeholder="请再次确认密码"></el-input>
            </el-form-item>
            <el-form-item label="备注：" :label-width="formLabelWidth">
                <el-input v-model="dataForm.tips" autocomplete="off" placeholder="备注"></el-input>
            </el-form-item>
            <el-form-item label="账号状态：" :label-width="formLabelWidth" prop="accountStatus">
                <el-switch 
                 v-model="dataForm.accountStatus"
                 active-color="#13ce66"
                 inactive-color="#ff4949"
                 active-value="1"
                 inactive-value="2"
                ></el-switch>
            </el-form-item>
          </el-form>
          <div class="nav">
              <el-divider content-position="center">分配店铺(站点)</el-divider>
          </div>
          <el-table 
            border 
            height="315"
            :data="distributionStoreList"
            default-expand-all
            :header-cell-style="{background:'#EFEFEF',textAlign:'center'}"
            >
             <el-table-column type="expand" align="center" width="80px">
                <template slot-scope="props">

                   <el-checkbox 
                     v-for="(data, index) in props.row.marketplaces" 
                     :label="data.id" 
                     :key="index"
                     @change="handleCheckOneChange($event, props.row, data.id)"
                     >{{data.value}}
                   </el-checkbox>
                </template>
             </el-table-column>
             <el-table-column label="店铺简称" prop="value" align="center"></el-table-column>
             <el-table-column label="区域" prop="market" align="center"></el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="asyncConfirm('dataFormRef')">确 定</el-button>
          </div>
        </el-dialog>

        <!-- 编辑子账号 -->
        <el-dialog
          width="30%" 
          title="编辑子账号" 
          :visible.sync="editDialogFormVisible"
         >
           <el-row style="margin-bottom: 20px; font-size: 16px'; font-weight: bold">
              <el-button type="primary" icon="el-icon-setting" @click="setNewPassword">设置新密码</el-button>
           </el-row>
           <div class="nav">
              <el-divider content-position="center">子账号信息</el-divider>
          </div>
          <el-form :model="editDataForm" :inline="true" :rules="editDataFormRules" ref="editDataFormRef">
             <el-form-item label="昵称：" :label-width="formLabelWidth" prop="nickName">
               <el-input v-model="editDataForm.nickName" autocomplete="off" placeholder="请输入昵称"></el-input>
             </el-form-item>   
             <el-form-item label="角色：" :label-width="formLabelWidth" prop="Role">
                <el-select v-model="editDataForm.Role" placeholder="请选择角色" style="width: 175px">
                  <el-option v-for="item in editRoleList" :key="item.id" :label="item.value" :value="item.id">{{item.value}}</el-option>
                </el-select>
             </el-form-item>
             <el-form-item label="登陆账号：" :label-width="formLabelWidth" prop="Account">
                <el-input v-model="editDataForm.Account" autocomplete="off" placeholder="请输入登陆邮箱"></el-input>
            </el-form-item>
            <el-form-item label="登陆手机号：" :label-width="formLabelWidth">
                <el-input v-model="editDataForm.mobilePhone" autocomplete="off" placeholder="请输入登陆手机号"></el-input>
            </el-form-item>
            <el-form-item label="备注：" :label-width="formLabelWidth">
                <el-input v-model="editDataForm.tips" autocomplete="off" placeholder="备注"></el-input>
            </el-form-item>
            <el-form-item label="账号状态：" :label-width="formLabelWidth" prop="accountStatus">
                <el-switch 
                 v-model="editDataForm.accountStatus"
                 active-color="#13ce66"
                 inactive-color="#ff4949"
                 active-value="1"
                 inactive-value="2"
                ></el-switch>
            </el-form-item>
          </el-form>
          <div class="nav">
            <el-divider content-position="center">分配店铺(站点)</el-divider>
          </div>
          <el-table 
            border 
            height="315"
            :data="editAccountList"
            default-expand-all
            :header-cell-style="{background:'#EFEFEF',textAlign:'center'}"
          >
            <el-table-column type="expand" align="center" width="80px">
              <template slot-scope="props">
                 <el-checkbox
                   v-for="(item, index) in props.row.marketplaces" 
                   :label="item.id"
                   :key="index"
                   @change="handleCheckEditChange($event, props.row, item.id)"
                   v-model="item.checked"
                  >
                   {{item.value}}
                 </el-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="店铺简称" prop="value" align="center"></el-table-column>
            <el-table-column label="区域" prop="market" align="center"></el-table-column>
          </el-table>
          <div slot="footer" class="dialog-footer">
            <el-button @click="editDialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="asyncEditConfirm('editDataFormRef')">保 存</el-button>
          </div>
        </el-dialog>

        <!-- 设置密码 -->
        <el-dialog
          width="20%"
          title="设置密码"
          :visible.sync="setPassWordVisible"
         >
           <el-form :model="settingForm" status-icon :rules="settingRules" ref="setRuleForm"> 
             <el-form-item label="新密码：" :label-width="formLabelWidth" prop="pass">
               <el-input type="password" v-model="settingForm.pass" autocomplete="off" style="width: 100%"></el-input>
             </el-form-item>
             <el-form-item label="确认密码：" :label-width="formLabelWidth" prop="checkPass">
                <el-input type="password" v-model="settingForm.checkPass" autocomplete="off" style="width: 100%"></el-input>
             </el-form-item>
           </el-form>
           <div slot="footer" class="dialog-footer">
             <el-button @click="resetForm('setRuleForm')">重 置</el-button>
             <el-button type="primary" @click="submitForm('setRuleForm')">确 定</el-button>
           </div>
        </el-dialog>
      </div>
   </div>
</template>


<script>
 import {
    pageInit,
    addInit,
    addManager,
    listPage,   // 查询分页
    updateInit,
    modifyPassword,
    modifyStatus,   // 修改账户状态
    editManager
 } from '@/api/childAccount.js'
export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.settingForm.checkPass !== '') {
            this.$refs.setRuleForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.settingForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      var validatesubAccountPass = (rule, value, callback) => {
        if(value === '') {
           callback(new Error('请输入密码'));
        }else {
           if(this.dataForm.checkPass !== '') {
              this.$refs.dataFormRef.validateField('checkPass');
           }
           callback();
        }
      }
      var validatesubAccountPass2 = (rule, value, callback) => {
         if(value === '') { 
           callback(new Error('请再次输入密码'));
         }else if(value !== this.dataForm.pass) {
           callback(new Error('两次输入密码不一致!'));
         }
         callback();
      }
      var validatorPhone = (rule, value, callback) => {
         if (value === '') {
           callback(new Error('手机号不能为空'))
         } else if (!/^1\d{10}$/.test(value)) {
           callback(new Error('手机号格式错误'))
         } else {
           callback()
         }
      }
      return {
        shopId: "",
        currentDataId: "",
        total: 0,   // 总条数
        isChecked: false,
        loading: false,
        changePasswordId: "",
        arrList: [],
        currentlyCheckced: [],
        temporaryStorageList: [],
        multipleSelectOption: [],
        tableDataList: [],
        statusList: [],
        roleList: [],   // 角色列表
        editRoleList: [],
        distributionStoreList: [],
        editAccountList: [],
        dialogFormVisible: false,
        editDialogFormVisible: false,
        setPassWordVisible: false,
        formLabelWidth: '120px',
        pageInfoList: {
           current: 1,
           pageSize: 20,
           storeName: "",
           accountStatus: "",
        },
        dataForm: {
          nickName: "",  // 昵称
          Role: "",    // 角色
          Account: "",  // 账号
          mobilePhone: "",  // 手机号
          pass: "",  // 密码
          checkPass: "",  // 确认密码
          accountStatus: "1",  // 账号状态
          tips: "",  // 备注
        },
        editDataForm: {
          nickName: "",
          Role: "",
          Account: "",
          mobilePhone: "",
          tips: "",
          accountStatus: "",
        },
        settingForm: {
           pass: "",
           checkPass: "",
        },
        settingRules: {
          pass: [
            { required: true, validator: validatePass, trigger: 'blur' },
            { min: 6, max: 20, message: '密码长度设置在6到20个字符', trigger: 'blur' }
          ],
          checkPass: [
            { required: true, validator: validatePass2, trigger: 'blur' },
            { min: 6, max: 20, message: '密码长度设置在6到20个字符', trigger: 'blur' }
          ],
        },
        dataFormRules: {
          nickName: [
            { required: true, message: '请输入昵称', trigger: 'blur' },
          ],
          Role: [
            { required: true, message: '请选择角色', trigger: 'change' }
          ],
          Account: [
            { required: true, message: '请输入登录账号', trigger: 'blur' },
          ],
          mobilePhone: [
            { required: true, validator: validatorPhone, trigger: 'blur' }
          ],
          pass: [
            { message: '请输入登录密码', trigger: 'blur' },
            { required: true, validator: validatesubAccountPass, trigger: 'blur' },
            { min: 6, max: 20, message: '密码长度请设置在6到20个字符', trigger: 'blur' }
          ],
          checkPass: [
            { message: '请再次确认密码', trigger: 'change' },
            { required: true, validator: validatesubAccountPass2, trigger: 'blur' },
            { min: 6, max: 20, message: '密码长度请设置在6到20个字符', trigger: 'blur' }
          ],
          accountStatus: [
            { required: true, message: '请选择状态', trigger: 'change' }
          ]
        },
        editDataFormRules: {
          nickName: [
            { required: true, message: '请输入昵称', trigger: 'blur' },
          ],
          Role: [
            { required: true, message: '请选择角色', trigger: 'change' }
          ],
          Account: [
            { required: true, message: '请输入登录账号', trigger: 'change' }
          ],
          accountStatus: [
            { required: true, message: '请选择账号状态', trigger: 'blur' }
          ]
        },
      }
    },
    created() {
      this.getPageInit()
      this.tableSearch();
    },
    methods: {
      //  初始化数据
      getPageInit() {  
        pageInit()
        .then(res=> {
           if(res.data.code == 200) {
              this.statusList = res.data.data.status;
              this.multipleSelectOption = res.data.data.shop;
           }
        })
      },
      tableSearch() {
        this.pageInfoList.current = 1;
        this.pageInfoList.pageSize = 20;
        this.Search();
      },
      // 查询事件
      Search() {  
        this.loading = true; 
        let params = {
           current: this.pageInfoList.current,
           pageSize: this.pageInfoList.pageSize,
           status: this.pageInfoList.accountStatus,
           marketplaceId: this.pageInfoList.storeName.substring(this.pageInfoList.storeName.indexOf('-')+1),
           shopId: this.pageInfoList.storeName.substring(0, this.pageInfoList.storeName.indexOf('-'))
        }
        listPage(params)
        .then(res=> {
          this.loading = false;
          if(res.status == 200){
            this.tableDataList = res.data.values;
            this.total = res.data.pageInfo.total;
          }
        })
      },
      // 添加子账号
      addSubAccount() {   
        addInit()
        .then(res=> {
          if(res.data.code == 200) { 
            this.roleList = res.data.data.roleList;
            this.distributionStoreList = res.data.data.shopList;
          }
        })
        this.dataForm.nickName = "";
        this.dataForm.Role = "";
        this.dataForm.Account = "";
        this.dataForm.mobilePhone = "";
        this.dataForm.passWord = "";
        this.dataForm.confirmPassWord = "";
        this.dataForm.tips = "";
        this.dataForm.accountStatus = "1";
        this.dialogFormVisible = true;
      },   
      // 获取编辑子账号角色
      getEditRoleList() {
        addInit()
         .then(res=> {
            if(res.data.code == 200) {
               this.editRoleList = res.data.data.roleList;
            }
         })
      },
      // 编辑事件
      handleEdit(row) {   
        updateInit(row.id)
        .then(res=> {
          this.currentlyCheckced = [];
          this.temporaryStorageList = [];
          res.data.data.shopList.forEach(it=> {    // 当前编辑弹框全部勾选中情况(包含店铺id 和站点id)
            it.marketplaces.forEach(it2=> {
              console.log(it2, 'aaaaaaaaaaa');
              if(it2.checked == true) {
                this.currentlyCheckced.push({
                  marketplaceIds: it2.id,
                  shopId: it.id
                })
              }
            })
          })
          res.data.data.shopList.forEach(item=> {
            item.marketplaces.forEach(item2=> {
              console.log(item2);
              this.temporaryStorageList.push({
                shopId: item.id,
                marketplaceIds: item2.id
              })
            })
          })
          let result = res.data.data;
          this.currentDataId = res.data.data.id;
          if(res.data.code == 200) {
            this.changePasswordId = result.id,
            this.editDataForm.nickName = result.userName;
            this.editDataForm.Role = result.roleId;
            this.editDataForm.Account = result.userAccount;
            this.editDataForm.mobilePhone = result.userPhone;
            this.editDataForm.tips = result.tips;
            this.editDataForm.accountStatus = result.status;
            this.editAccountList = result.shopList;
          }
        })
        this.getEditRoleList();
        this.editDialogFormVisible = true;
      },
      // 编辑确定事件
      asyncEditConfirm(formName) {
         this.$refs[formName].validate(valid => {
           if(valid) {
             this.editAccountList.forEach(data=> {
               data.marketplaces.forEach(data2=> {
                 this.isChecked = data2.checked;
               })
             })
             if(this.isChecked == true) {     // 如果都为true
               let data= {
                 id: this.currentDataId,
                 userName: this.editDataForm.nickName,
                 roleId: this.editDataForm.Role,
                 userAccount: this.editDataForm.Account,
                 userPhone: this.editDataForm.mobilePhone,
                 tips: this.editDataForm.tips,
                 status: this.editDataForm.accountStatus,
                 shopList:  this.temporaryStorageList,
               }
               editManager(data)
               .then(res=> {
                 if(res.data.code == 200) {
                   this.$message.success(res.data.message);
                   this.editDialogFormVisible = false;
                   this.Search();
                 }else if(res.data.code == 500) {
                   this.$message.error(res.data.message);
                 }
               })
             }else {
                let data= { 
                  id: this.currentDataId,
                  userName: this.editDataForm.nickName,
                  roleId: this.editDataForm.Role,
                  userAccount: this.editDataForm.Account,
                  userPhone: this.editDataForm.mobilePhone,
                  tips: this.editDataForm.tips,
                  status: this.editDataForm.accountStatus,
                  shopList: this.currentlyCheckced,
                }  
                editManager(data)
                .then(res=> {
                  if(res.data.code == 200) {
                    this.$message.success(res.data.message);
                    this.editDialogFormVisible = false;
                    this.Search();
                  }else if(res.data.code == 500){
                    this.$message.error(res.data.message);
                  }
                })
             }
           }else {
             return false;
           }
         })
      },
      handleSizeChange(newSize) {
        this.pageInfoList.pageSize = newSize;
        this.Search();
      },
      handleCurrentChange(newCurrent) {
         this.pageInfoList.current = newCurrent;
         this.Search();
      },
      asyncConfirm(formName) {
        this.$refs[formName].validate((valid) => {
          if(valid) {
            let data = {
               roleId: this.dataForm.Role,
               status: this.dataForm.accountStatus,
               tips: this.dataForm.tips,
               userAccount: this.dataForm.Account,
               userName: this.dataForm.nickName,
               userPassword: this.dataForm.pass,
               userPhone: this.dataForm.mobilePhone,
               shopList: this.arrList,
            }
            addManager(data)
            .then(res=> {
               if(res.data.code == 200) {
                 this.$message.success(res.data.message);
                 this.dialogFormVisible = false;
                 this.Search();
               }else if(res.data.code == 500) {
                 this.$message.error(res.data.message);
               }
            })
          }
        })  
      },
      handleCheckOneChange($event, row, currentId) {
         if($event) {
            row.marketplaces.forEach((item, index)=> {
               if (currentId == item.id) {
                 this.arrList.push({
                   shopId: row.id,
                   marketplaceIds: item.id
                 });
               }
            })
         }else if(!$event){
            this.arrList.forEach((item2, index)=> {
               if(item2.marketplaceIds == currentId&&item2.shopId == row.id) {
                 this.arrList.splice(index, 1)
               }
            }) 
         }
      }, 
      //  设置新密码
      setNewPassword() {
        this.settingForm.pass = "";
        this.settingForm.checkPass = "";
        this.setPassWordVisible = true;
      },
      // 勾选状态变化
      handleCheckEditChange($event, row, currentEditId) {
        // 如果是false 取消, 如果true 添加;
        if($event) {
           row.marketplaces.forEach((list, index)=> {
             if(currentEditId == list.id) {
               this.currentlyCheckced.push({
                   shopId: row.id,
                   marketplaceIds: list.id
               });
             }
           })
        }else {
          this.currentlyCheckced.forEach((item3, index)=> {
            if(item3.marketplaceIds == currentEditId&& item3.shopId == row.id) {
              this.currentlyCheckced.splice(index, 1)
            }
          })
        }
      },
      //  重置事件
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 确定事件 
      submitForm(formName) {
         this.$refs[formName].validate(valid => {
           if(valid) {
              let data = {
                id: this.changePasswordId,
                newPassword: this.settingForm.pass,
                newPasswordAgain: this.settingForm.checkPass
              }
              modifyPassword(data)
              .then(res=> {
                if(res.data.code == 200) {
                  this.$message.success(res.data.message);
                  this.$refs[formName].resetFields();
                  this.setPassWordVisible = false;
                }else if(res.data.code == 500){
                  this.$message.error(res.data.message);
                }
              })
           }else {
               return false;
           }
         })
      },
      // 修改用户事件
      changeAccountStatus($event, row) {   // 1: 启用  2: 禁用
        let data = {
           id: row.id,
           status: $event
        }
        modifyStatus(data)
        .then(res=> {
          if(res.data.code == 200) {   
            this.$message.success(res.data.message);
            setTimeout(()=> {
               this.Search();
            }, 2000)
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
      },
    }
}
</script>

<style lang="scss" scoped>
.storage {
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-select {
        margin-right: 20px;
      }
      .el-table {
          margin-top: 30px;
      }
      .el-pagination {
        padding-top: 40px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
.nav {
  width: 100%;
  text-align: left;
  padding-left: 15px;
  line-height: 45px;
}
</style>