import request from '@/utils/request'
//  子账号模块 == 用户管理

// 查询初始化
export function pageInit() {
   return request({
     url: "/api/user/manager/pageInit",
     method: "get"
   })
}

// 新增初始化
export function addInit() {
    return request({
       url: "/api/user/manager/addInit",
       method: "get"
    })
}

// 新增接口
export function addManager(data) {
    return request({
       url: "/api/user/manager", 
       data,
       method: "post"
    })
}

// 查询接口
export function listPage(params) {
    return request({
       url: "/api/user/manager/listPage",
       params,
       method: "get"
    })
}

// 编辑初始化
export function updateInit(id) {
    return request({
       url: "/api/user/manager/updateInit/" + id,
       method: "get"
    })
}

// 编辑账户密码
export function modifyPassword(data) {
    return request({
       url: "/api/user/manager/password",
       data,
       method: "put"
    })
}

// 修改用户状态
export function modifyStatus(data) {
    return request({
        url: "/api/user/manager/status",
        data,
        method: "post"
    })
}

// 编辑事件
export function editManager(data) {
    return request({
      url: "/api/user/manager",
      data,
      method: "put"
    })
}